#footer{
  bottom: 0%;
  left: 0%;
  right: 0%;
  display: flex;
  background-color: black;
  color: white;
  width: 100%;
}

#footer ul{
  margin: 0 auto;
  text-align: center;
}