.project-header{
  text-align: center;
  margin-top: 250px;
}

p{
  margin-top: 10px;
  line-height: 150%;
}

@media only screen and (min-width: 850px){
  .project-header{
    line-height: 100px;
    display: inline;
    text-align: center;
  }
}