.about-header{
  text-align: center;
  margin-top: 250px;
  
}

p{
  margin-top: 10px;
  line-height: 150%;
  
}

.profile-photo{
  float: left;
  width: 25%;
  margin-top: 32px;
  margin-right: 15px;
  border: solid black;
  border-radius: 5px;
  margin-top: 10px;
}

@media only screen and (min-width: 850px){
.about-header{
  line-height: 100px;
  display: inline;
  text-align: center;
}
}